<template>
  <div>
    <!-- <van-nav-bar
        title="修改微信号"
        left-arrow
        @click-left="onClickLeft"
    /> -->
    <div class="pwd-input">
        <van-field v-model="oldpwd" type="number" label="旧微信号" :colon='true' />
        <van-field v-model="newpwd" type="number" label="新微信号" :colon='true' />
        <!-- <van-field v-model="confirmpwd" type="number" label="确认微信号" :colon='true' /> -->
    </div>
    <div class="upload-botm">
          <van-button round block color="#23B8FF" class="botm-btn">确认修改</van-button>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            oldpwd: '13219322242',
            newpwd: '987654321',
            confirmpwd: '987654321'
        }
    },
    methods: {
        onClickLeft() {
            window.history.go(-1);
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__title {
    font-size: 16px;
    font-weight: 700;
    color: #333333 ;
}
/deep/.van-nav-bar__content {
    border-bottom: 8px solid #F7F7F7;
}
 /deep/ .van-icon-arrow-left:before {
    color: #6A6A6A;
}
 /deep/ .van-field__label {
    color: #333333;
    margin-right: 0;
    font-size: 12px;
}
 /deep/ .van-field__control {
     color: #666666;
     font-size: 12px;
}
.pwd-input {
    margin-top: 10px;
}
.upload-botm {
    padding: 20px;
    margin-top: 100%;
    .van-button--round {
    border-radius: 10px;
  }
}
</style>